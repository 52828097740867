.c-contact-form {
  overflow-x: hidden;
  padding: 30px 0;

  @include bp-medium {
    padding: 60px 0;
  }
}

.c-contact-form__wrapper {
  max-width: 840px;
  margin: 0 auto;
}

.c-contact-form__form {
  // stylelint-disable selector-class-pattern

  .gform_confirmation_wrapper {
    font-size: 2rem;
  }

  .gform_body {
    margin-bottom: 30px;
  }

  .validation_error {
    font-size: 1.7rem;
    margin-bottom: 1em;
    color: $color-red2;
  }

  .gform_fields {
    list-style: none;
    margin: 0 -15px;
    display: flex;
    flex-wrap: wrap;
  }

  .gfield {
    margin: 0;
    width: 100%;
    margin-bottom: 20px;
    padding: 0 15px;

    &.width-half {
      @include bp-medium {
        width: 50%;
      }
    }

    .validation_message {
      font-size: 1.7rem;
      color: $color-red2;
    }
  }

  .gfield_label {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    font-size: 2rem;
    font-weight: 700;
  }

  .gfield_checkbox label {
    font-size: 2rem;
    font-weight: 700;
  }

  .gfield_checkbox input {
    width: 14px;
    height: 14px;
    margin-right: 14px;
  }

  .ginput_container {
    input:not([type='checkbox']),
    textarea {
      display: block;
      width: 100%;

      border: 1px solid rgba($color-light-grey, 0.6);
      border-radius: 3px;
      padding: 3px;

      font: inherit;
      line-height: inherit;
      font-size: 2rem;
    }

    textarea {
      height: calc(7.2em + 8px); // 6 * 1.2em + 8px
      resize: vertical;
    }
  }

  .ginput_container_checkbox {
    ul,
    li {
      list-style: none;
      margin: 0;
    }
  }

  .gform_footer {
    input[type='submit'] {
      @include btn-reset;
      appearance: none;

      background-color: $color-red;
      color: $color-white;

      font: inherit;
      line-height: inherit;
      font-size: 2.4rem;
      padding: 8px;
      min-width: 150px;
      border-radius: 10px;
      cursor: pointer;
    }
  }

  // stylelint-enable selector-class-pattern
}
