/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

$wrapper-padding: 10px;

.o-wrapper {
  margin-right: auto;
  margin-left: auto;

  padding-right: $wrapper-padding;
  padding-left: $wrapper-padding;

  max-width: 1010px + (2 * $wrapper-padding);

  &--big {
    max-width: 1100px + (2 * $wrapper-padding);
  }
}
