.c-storage-offer__top {
  margin-bottom: 25px;
  color: $color-blue;
}

.c-storage-offer__info {
  background-color: $color-blue-light;
  padding: 20px 0 30px;
  margin-bottom: 20px;
}

.c-storage-offer__info-top {
  margin-bottom: 10px;
  color: $color-blue;
}

.c-storage-offer__info-top-unit {
  font-size: 2.4rem;
}

.c-storage-offer__info-top-dimensions {
  font-size: 2.4rem;
  font-weight: 400;
  display: inline-block;
  padding: 0 10px;
}

.c-storage-offer__info-top-volume {
  font-size: 3.6rem;
}

.c-storage-offer__info-separator {
  display: inline-block;
  width: 100%;
  padding: 0.5em 0;
  background-color: $color-blue;
  background-clip: content-box;
  box-sizing: content-box;
  height: 1px;
  max-width: 390px;
}

.c-storage-offer__form {
  .wpcf7-response-output {
    margin: 0;
    margin-top: 20px;
    padding: 10px;
    font-size: 2rem;
    color: $color-blue;
    font-weight: 700;
  }

  .wpcf7-validation-errors {
    border-color: $color-red;
  }
}

.c-storage-offer__form-section {
  overflow-x: hidden;
  margin-bottom: 45px;
}

.c-storage-offer__form-heading {
  margin-bottom: 15px;
  color: $color-red;
}

.c-storage-offer__form-group {
  margin-bottom: 25px;
}

.c-storage-offer__form-list {
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: wrap;

  font-size: 2rem;
  font-weight: 700;
  color: $color-blue;

  @include bp-large {
    font-size: 2.7rem;
  }

  &:last-child {
    padding-top: 40px;
  }
}

.c-storage-offer__form-row {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 0;
  padding: 1px 0;

  display: flex;
  flex-wrap: wrap;

  &--half {
    @include bp-medium {
      width: 50%;
    }
  }
}

.c-storage-offer__form-label-wrapper,
.c-storage-offer__form-input-wrapper {
  flex: 0 0 auto;
  width: 100%;
  padding: 3px 0;

  @include bp-medium {
    width: 50%;
    padding: 0;
  }
}

.c-storage-offer__form-label-wrapper {
  padding: 5px 5px 5px 0;

  @include bp-medium {
    width: 44%;
  }

  .c-storage-offer__form-row--half:nth-child(2n) > & {
    @include bp-medium {
      width: 35%;
      padding-left: 35px;
    }
  }

  .c-storage-offer__form-row--full & {
    @include bp-medium {
      width: 22%;
    }
  }

  .c-storage-offer__form-row--full2 & {
    @include bp-medium {
      width: 40%;
    }
  }

  br {
    display: none;

    @include bp-medium {
      display: inline;
    }
  }
}

.c-storage-offer__form-input-wrapper {
  @include bp-medium {
    width: 56%;
  }

  .c-storage-offer__form-row--half & {
    align-self: flex-end;
  }

  .c-storage-offer__form-row--half:nth-child(2n) > & {
    @include bp-medium {
      width: 65%;
    }
  }

  .c-storage-offer__form-row--full & {
    @include bp-medium {
      width: 78%;
    }
  }

  .c-storage-offer__form-row--full2 & {
    @include bp-medium {
      width: 60%;
    }
  }

  > span {
    &,
    > input,
    > select,
    > textarea {
      display: block;
      width: 100%;
    }

    > select {
      &::-ms-expand {
        display: none;
      }
    }

    > textarea {
      height: calc(4.8em + 10px);
      resize: vertical;
    }

    > input[type='date'] {
      min-height: calc(1.2em + 10px);

      &::-webkit-inner-spin-button {
        height: 1.2em;
      }

      &::-webkit-datetime-edit-fields-wrapper {
        padding: 0;
      }
    }

    > input,
    > select,
    > textarea {
      appearance: none;
      background: none;
      font: inherit;
      line-height: inherit;
      color: inherit;
      background-color: transparent;

      font-weight: 400;
      padding: 4px 15px;

      border: 1px solid rgba($color-light-grey, 0.6);
      border-radius: 3px;
    }
  }
}

.c-storage-offer__form-additional {
  font-weight: 400;

  .c-storage-offer__form-row--half & {
    padding: 5px 0;

    @include bp-medium {
      width: 200%;
    }
  }
}

.c-storage-offer__form-submit-wrapper {
  margin-top: 40px;

  .wpcf7-submit {
    @include btn-reset;
    font: inherit;
    line-height: inherit;
    font-size: 2.4rem;
    border-radius: 8px;
    background-color: $color-red;
    color: $color-white;
    padding: 11px 10px;
    min-width: 150px;
    cursor: pointer;

    .x5-is-submitting & {
      pointer-events: none;
      opacity: 0.7;
    }
  }
}

.c-storage-offer__bottom {
  margin-bottom: 35px;
}
