$font-base: '../assets/fonts/';

@font-face {
  font-family: 'PT Sans Caption';
  src: url($font-base + 'PTC55F.woff2') format('woff2'),
    url($font-base + 'PTC55F.woff') format('woff');

  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'PT Sans Caption';
  src: url($font-base + 'PTC75F.woff2') format('woff2'),
    url($font-base + 'PTC75F.woff') format('woff');

  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'PT Sans Narrow';
  src: url($font-base + 'PTN57F.woff2') format('woff2'),
    url($font-base + 'PTN57F.woff') format('woff');

  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'PT Sans Narrow';
  src: url($font-base + 'PTN77F.woff2') format('woff2'),
    url($font-base + 'PTN77F.woff') format('woff');

  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'PT Sans';
  src: url($font-base + 'PTS55F.woff2') format('woff2'),
    url($font-base + 'PTS55F.woff') format('woff');

  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: 'PT Sans';
  src: url($font-base + 'PTS56F.woff2') format('woff2'),
    url($font-base + 'PTS56F.woff') format('woff');

  font-style: italic;
  font-weight: 400;
}

@font-face {
  font-family: 'PT Sans';
  src: url($font-base + 'PTS75F.woff2') format('woff2'),
    url($font-base + 'PTS75F.woff') format('woff');

  font-style: normal;
  font-weight: 700;
}

@font-face {
  font-family: 'PT Sans';
  src: url($font-base + 'PTS76F.woff2') format('woff2'),
    url($font-base + 'PTS76F.woff') format('woff');

  font-style: italic;
  font-weight: 700;
}
