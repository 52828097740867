// stylelint-disable selector-max-id, selector-max-compound-selectors, property-no-vendor-prefix, max-nesting-depth, selector-class-pattern

#body {
  .woocommerce-info,
  .woocommerce-message {
    border-top-color: $color-blue;
    color: $color-blue;

    &::before {
      color: $color-blue;
    }
  }

  .woocommerce-error {
    margin-left: 0;
    color: $color-blue;
  }

  .woocommerce {
    .shop_table {
      // cart -> product table

      th {
        text-align: inherit;
      }
    }

    .quantity {
      // cart -> quantity
      padding-right: 27px;

      .input-text {
        font: inherit;
        line-height: inherit;
        color: $color-blue;

        -moz-appearance: textfield;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          appearance: none;
        }
      }

      .plus,
      .minus {
        color: inherit;
        background-color: $color-white;
      }
    }

    .coupon {
      // cart -> coupon code
      display: flex;
      max-width: 100%;

      .input-text {
        flex: 1 1 auto;
        width: auto;
        max-width: 100px;

        @include bp-small {
          max-width: 125px;
        }
      }

      .button {
        flex: 1 1 auto;
        width: auto;

        @include bp-small {
          flex: 0 0 auto;
        }
      }
    }

    .checkout_coupon {
      // checkout -> coupon
      display: flex;
      flex-wrap: wrap;

      .form-row {
        flex: 0 0 auto;
        width: 100%;
        display: flex;

        @include bp-small {
          width: auto;
        }
      }

      .input-text {
        flex: 1 1 auto;
        display: block;
        width: auto;

        @include bp-small {
          max-width: 125px;
        }
      }
    }

    .button {
      font: inherit;
      line-height: inherit;

      color: $color-white;
      background-color: $color-blue;
    }

    .button.checkout-button,
    .place-order > .button {
      background-color: $color-red;
      font-size: 2.6rem;
      padding: 0.7em;
    }

    .input-text {
      font: inherit;
      font-weight: 400;
      line-height: inherit;
      color: inherit;

      &::placeholder {
        font-family: inherit;
        color: inherit;
        opacity: 0.5;
      }
    }

    .woocommerce-invalid,
    .woocommerce-validated {
      > .input-text {
        border-style: solid;
      }
    }

    .select2 {
      font-weight: 400;

      .select2-selection__rendered {
        color: inherit;
      }

      .select2-selection__arrow {
        display: none;
      }
    }
  }

  #payment {
    // checkout -> payment
    background: $color-blue-light;

    .payment_box {
      background-color: darken($color-blue-light, 5%);
      color: $color-blue;

      &::before {
        border-bottom-color: darken($color-blue-light, 5%);
      }
    }
  }

  .woocommerce-order-overview {
    // checkout -> thankyou

    li {
      border-right-width: 0;
      margin-bottom: 5px;

      @include bp-small {
        border-right-width: 1px;
      }
    }
  }

  .required {
    // marker next to fields name
    text-decoration: none;
  }
}

// stylelint-enable selector-max-id, selector-max-compound-selectors, property-no-vendor-prefix, max-nesting-depth, selector-class-pattern
