.o-wysiwyg-content {
  @include clearfix;

  font-size: 2rem;

  word-wrap: break-word;
  overflow-wrap: break-word;

  ol,
  ul {
    margin-left: 1em;
    margin-bottom: 1.2em;
  }

  li {
    padding-left: 0.5em;
    margin-bottom: 0.1em;

    > ul,
    > ol {
      margin-bottom: 0;
      margin-top: 0;
      margin-left: 1em;
    }

    > ul {
      list-style-type: disc;
    }
  }


  h1 {
    color: $color-red;
  }

  &--bold {
    font-weight: 700;
  }

  &--blue {
    color: $color-blue;
  }

  &--white {
    color: $color-white;
  }

  &--red {
    color: $color-red;
  }
}
