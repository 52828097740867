/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background-color: $color-blue;
  color: $color-white;
  text-align: center;
  padding: 25px 0 40px;
}

.c-footer__wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.c-footer__social {
  display: inline-flex;
  padding: 20px 0;
}

.c-footer__social-link {
  flex: 0 0 auto;
  width: 58px;
  height: 58px;

  background-color: $color-white;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.c-footer__social-icon {
  display: block;
  width: 35px;
  height: 35px;
  transition: fill 0.25s;
  fill: $color-blue;

  :hover > & {
    fill: rgba($color-blue, 0.8);
  }
}

.c-footer__bottom-content {
  font-size: 1.2rem;
  margin-bottom: 1.8em;

  @include bp-medium {
    font-size: 1.6rem;
  }
}

.c-footer__links {
  font-size: 1.6rem;
  margin-bottom: 5px;
}

.c-footer__links-list {
  list-style: none;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
}

.c-footer__links-el {
  width: 100%;
  margin: 0;
  padding: 0 10px;
  position: relative;

  @include bp-small {
    width: auto;
  }

  &:after {
    content: '';
    position: absolute;
    top: 20%;
    right: -0.5px;
    width: 1px;
    height: 60%;
    background-color: currentColor;
    display: none;

    @include bp-small {
      display: block;
    }
  }

  &:last-of-type:after {
    display: none;
  }
}

.c-footer__links-el-link {
  text-decoration: none;
  display: block;
  transition: opacity 0.25s;

  &:hover {
    opacity: 0.8;
  }
}

.c-footer__copyright {
  font-size: 1.6rem;
}
