.c-moving-supplies-tabs__top-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.c-moving-supplies-tabs__top-left {
  display: flex;
  overflow-x: auto;
  list-style: none;
  margin: 0;
}

.c-moving-supplies-tabs__top-tab {
  margin: 0;
  margin-right: 15px;

  &:last-child {
    margin-right: 0;
  }
}

.c-moving-supplies-tabs__top-tab-button {
  @include btn-reset;
  display: block;
  font: inherit;
  line-height: inherit;
  font-size: 2rem;
  font-weight: 700;
  color: $color-white;
  background-color: $color-blue;
  padding: 8px 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  min-width: 175px;
  cursor: pointer;

  [aria-selected='true'] > & {
    background-color: lighten($color-blue, 10%);
  }

  &--red {
    background-color: $color-red;

    [aria-selected='true'] > & {
      background-color: lighten($color-red, 10%);
    }
  }
}

.c-moving-supplies-tabs__top-right {
  flex: 0 0 auto;
}

.c-moving-supplies-tabs__top-cart-link {
  display: block;
  text-decoration: none;
  position: relative;
  padding: 6px 10px 8px 8px;

  @include bp-medium {
    padding: 10px 15px 8px 8px;
  }
}

.c-moving-supplies-tabs__top-cart-icon {
  display: block;
  width: 40px;
  height: auto;

  @include bp-medium {
    width: 50px;
  }
}

.c-moving-supplies-tabs__top-counter {
  position: absolute;
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  background-color: $color-red2;
  color: $color-white;
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  z-index: 400;

  @include bp-medium {
    width: 27px;
    height: 27px;
    font-size: 2rem;
  }

  &[data-value='0'] {
    display: none;
  }
}

.c-moving-supplies-tabs__bottom {
  background-color: $color-blue-light;
  padding: 30px 0 50px;

  @include bp-medium {
    padding: 40px 0 150px;
  }
}
