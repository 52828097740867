.c-moving-supplies-tab-products__heading {
  color: $color-blue;
  margin-bottom: 10px;
}

.c-moving-supplies-tab-products__row {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 45px;
}

.c-moving-supplies-tab-products__left {
  flex: 0 0 auto;
  width: 100%;
  margin-bottom: 20px;

  @include bp-medium {
    width: 28%;
    padding-right: 50px;
    margin-bottom: 0;
  }

  @include bp-large {
    padding-right: 85px;
  }

  &--empty {
    margin-bottom: 0;
  }
}

.c-moving-supplies-tab-products__right {
  flex: 1 0 auto;
  width: 100%;

  @include bp-medium {
    width: 0;
  }
}

.c-moving-supplies-tab-products__image {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto;
  height: auto;
}

.c-moving-supplies-tab-products__preview {
  margin-bottom: 35px;
}

.c-moving-supplies-tab-products__add {
  font-size: 2rem;
  color: $color-blue;
  font-weight: 700;

  display: flex;
  width: 275px;
  justify-content: space-between;
}

.c-moving-supplies-tab-products__add-left {
  display: flex;

  .added > & {
    display: none;
  }
}

.c-moving-supplies-tab-products__add-right {
  display: flex;
}

.c-moving-supplies-tab-products__add-input {
  appearance: none;
  -moz-appearance: textfield; // stylelint-disable-line
  margin: 0 5px;
  border: 0;
  padding: 5px 0;
  background: none;
  font: inherit;
  line-height: inherit;
  color: inherit;
  text-align: center;
  width: 30px;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    appearance: none;
  }
}

.c-moving-supplies-tab-products__add-button {
  @include btn-reset;
  font: inherit;
  line-height: inherit;
  color: inherit;

  width: 45px;
  padding: 5px 0;
  background-color: $color-white;
  cursor: pointer;
}

.c-moving-supplies-tab-products__add-add,
.c-moving-supplies-tab-products__add-right > .wc-forward {
  flex: 0 0 auto;
  margin: 0;
  display: block;
  text-decoration: none;
  background-color: $color-blue;
  color: $color-white;
  padding: 5px 9px !important;
}

.c-moving-supplies-tab-products__add-add {
  &.added {
    display: none;
  }
}
