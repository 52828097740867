/* ==========================================================================
   #MAIN NAV
   ========================================================================== */

.c-main-nav__list {
  display: flex;
  list-style: none;
  margin: 0;
}

.c-main-nav__item {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
  color: $color-red;

  margin-right: 2rem;
  position: relative;

  &:last-of-type {
    margin-right: 0;
  }

  &--blue {
    color: $color-blue;
  }
}

.c-main-nav__item-button {
  @include btn-reset;
  font: inherit;
  line-height: inherit;
  color: inherit;
  display: block;
  cursor: pointer;
}

.c-main-nav__item-link {
  text-decoration: none;
  display: block;
}

.c-main-nav__sublist {
  list-style: none;
  margin: 0;

  position: absolute;
  top: 100%;
  left: -10px;
  width: auto;
  min-width: 130px;
  height: auto;
  padding-top: 10px;
  padding-bottom: 15px;

  background-color: $color-white;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;

  opacity: 0;
  transition: visibility 0s 0.25s, opacity 0.25s;
  visibility: hidden;

  .c-main-nav__item.is-open > & {
    opacity: 1;
    visibility: inherit;
    transition-delay: 0s;
  }
}

.c-main-nav__subitem {
  margin: 0;

  font-size: 2.3rem;
  font-weight: 400;
  margin-bottom: 5px;

  &:last-of-type {
    margin-bottom: 0;
  }
}

.c-main-nav__subitem-link {
  text-decoration: none;
  padding: 0 10px;
  display: block;
  white-space: nowrap;
}
