.c-btn-menu {
  width: 30px;
  height: 30px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.c-btn-menu__shapes {
  position: relative;
  width: 20px;
  height: 12px;
  transform: translateZ(0);
  opacity: 0;
  animation: anim-in 0.4s forwards;
}

.c-btn-menu__shape {
  position: absolute;
  top: calc(50% - 1px);
  left: 0;
  display: block;
  width: 100%;
  height: 2px;
  background: $color-blue;
  animation: anim-in 0.4s;
  animation-duration: 0.4s;
  animation-fill-mode: forwards;

  &--top {
    animation-name: t-top-backward;
    transform: translate3d(0, -6px, 0);
  }

  &--middle {
    animation-name: t-middle-backward;
    transform: translate3d(0, 0, 0);
  }

  &--bottom {
    animation-name: t-bottom-backward;
    transform: translate3d(0, 6px, 0);
  }

  .c-btn-menu__shapes.is-mobile-menu-open > & {
    animation-direction: forwards;
    animation-iteration-count: 1;

    &--top {
      animation-name: t-top;
    }

    &--middle {
      animation-name: t-middle;
    }

    &--bottom {
      animation-name: t-bottom;
    }
  }
}

@keyframes anim-in {
  0% {
    opacity: 0;
  }

  99% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes t-top {
  50% {
    transform: translateY(0) translateZ(0);
  }

  100% {
    transform: rotate(45deg) translateZ(0);
  }
}

@keyframes t-top-backward {
  0% {
    transform: rotate(45deg) translateZ(0);
  }

  50% {
    transform: translateY(0) translateZ(0);
  }
}

@keyframes t-middle {
  0% {
    opacity: 1;
  }

  49% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes t-middle-backward {
  0% {
    opacity: 0;
  }

  49% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
}

@keyframes t-bottom {
  50% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(-45deg) translate3d(0, 0, 0);
  }
}

@keyframes t-bottom-backward {
  0% {
    transform: rotate(-45deg) translate3d(0, 0, 0);
  }

  50% {
    transform: translate3d(0, 0, 0);
  }
}
