// open select2 styling (used by woocommerce)

.select2-container {
  font-family: $font-default;
  font-size: 2rem;
  color: $color-blue;
  line-height: 1.2;
}

.select2-search__field {
  font: inherit;
  line-height: inherit;
  color: inherit;
}
