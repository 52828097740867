.o-accordion__content-wrapper {
  height: 0;
  overflow: hidden;

  :not(.animating) > & {
    visibility: hidden;
  }

  .animating > & {
    transition: height 0.25s;
  }

  .is-open > & {
    visibility: visible;
  }

  .is-open:not(.animating) > & {
    height: auto;
  }
}
