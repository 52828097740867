.c-contact-top {
  background-color: $color-blue2;
}

.c-contact-top__wrapper {
  @include bp-xlarge {
    margin-left: -150px;
  }
}

.c-contact-top__cols {
  display: flex;
  flex-wrap: wrap;
}

.c-contact-top__col-map {
  flex: 1 0 auto;
  width: 100%;

  @include bp-large {
    width: 0;
    padding-right: 30px;
  }
}

.c-contact-top__col-text {
  flex: 0 0 auto;
  align-self: center;
  width: 100%;
  padding: 20px 0;

  @include bp-large {
    width: 31%;
  }
}

.c-contact-top__map {
  display: block;
  width: 100%;
  height: auto;
}

.c-contact-top__wysiwyg {
  font-size: 1.5rem;

  @include bp-xlarge {
    font-size: 1.8rem;
  }
}
