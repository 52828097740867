.c-hero {
  margin-bottom: 10px;

  @include bp-medium {
    margin-bottom: 30px;
  }
}

.c-hero__wrapper {
  position: relative;
}

.c-hero__image {
  display: block;
  width: 100%;
  height: auto;
}

.c-hero__overlay {
  padding: 10px 0;
  display: flex;

  @include bp-medium {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.c-hero__overlay-wrapper {
  width: 100%;
  max-width: 1900px;
  display: flex;
  align-items: center;
  position: relative;

  .c-hero__overlay--left-left & {
    align-items: flex-start;

    @include bp-large {
      align-items: center;
      margin-top: -100px;
    }

    @include bp-xlarge {
      margin-top: 0;
    }
  }
}

.c-hero__overlay-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  color: $color-blue;
  position: relative;

  @include bp-medium {
    color: $color-white;
    justify-content: flex-end;
  }

  .c-hero__overlay--left-col & {
    flex-direction: column;

    // @include bp-medium {
    //   position: absolute;
    //   left: 3vw;
    // }

    // @include bp-full {
    //   left: 4vw;
    // }
  }

  .c-hero__overlay--left-right &,
  .c-hero__overlay--left-left & {
    @include bp-medium {
      justify-content: flex-start;
      width: auto;
    }

    @include bp-xlarge {
      transform: translateX(-120px);
    }
  }
}

.c-hero__overlay-price {
  width: 100px;
  height: 100px;
  margin-right: 20px;

  @include bp-medium {
    width: 120px;
    height: 120px;
  }

  @include bp-large {
    width: 160px;
    height: 160px;
  }

  .c-hero__overlay--left-col & {
    @include bp-medium {
      width: 110px;
      height: 110px;
    }

    @include bp-large {
      width: 150px;
      height: 150px;
    }

    @include bp-full {
      width: 215px;
      height: 215px;
    }
  }
}

.c-hero__overlay-text {
  .c-hero__overlay--left-left & {
    @include bp-medium {
      display: flex;
      align-items: center;
      max-width: 340px;
    }

    @include bp-large {
      max-width: none;
    }
  }

  p {
    margin-bottom: 0.6em;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .c-hero__overlay--left-col & {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;

    p {
      margin-bottom: 0.3em;
    }

    @include bp-medium {
      margin-left: 0;
    }
  }
}

.c-hero__overlay-group {
  .c-hero__overlay--left-left & {
    flex: 0 1 auto;
  }
}

.c-hero__overlay-text-big {
  font-size: 2.8rem;
  line-height: 0.9;
  font-weight: 700;
  margin-bottom: 10px;

  @include bp-medium {
    font-size: 4rem;
  }

  @include bp-large {
    font-size: 5.6rem;
  }

  .c-hero__overlay--left-left & {
    font-size: 2.8rem;

    @include bp-large {
      font-size: 4rem;
    }

    @include bp-xlarge {
      font-size: 5.6rem;
    }
  }

  .c-hero__overlay--left-col & {
    display: flex;
    align-items: center;

    @include bp-medium {
      font-size: 2.6rem;
      margin-bottom: 0;
    }

    @include bp-large {
      font-size: 3.6rem;
    }

    @include bp-xlarge {
      font-size: 4rem;
    }

    @include bp-full {
      font-size: 5.6rem;
    }
  }
}

.c-hero__overlay-text-small {
  font-size: 2rem;
  line-height: 1;
  font-weight: 700;

  @include bp-medium {
    font-size: 2.2rem;
  }

  @include bp-large {
    font-size: 2.8rem;
  }

  .c-hero__overlay--left-left & {
    font-size: 2rem;

    @include bp-large {
      font-size: 2.2rem;
    }

    @include bp-xlarge {
      font-size: 2.8rem;
    }
  }

  .c-hero__overlay--left-col & {
    @include bp-medium {
      font-size: 0.95rem;
    }

    @include bp-large {
      font-size: 1.3rem;
    }

    @include bp-xlarge {
      font-size: 1.4rem;
    }

    @include bp-full {
      font-size: 2.1rem;
    }
  }
}

.c-hero__overlay-text-medium {
  .c-hero__overlay--left-col & {
    font-size: 2.2rem;
    line-height: 1;
    font-weight: 700;

    @include bp-medium {
      font-size: 2.1rem;
    }

    @include bp-large {
      font-size: 2.97rem;
    }

    @include bp-xlarge {
      font-size: 3.8rem;
    }

    @include bp-full {
      font-size: 4.6rem;
    }
  }
}

.c-hero__overlay-text-separator {
  flex: 0 0 auto;
  background-color: $color-blue;

  @include bp-medium {
    background-color: $color-white;
  }

  height: 3px;
  margin: 10px 0;

  @include bp-large {
    margin: 20px 0;
  }

  @include bp-xlarge {
    margin: 30px 0;
  }

  .c-hero__overlay--left-left & {
    @include bp-medium {
      width: 3px;
      height: 100%;
      margin: 0 10px;
    }

    @include bp-large {
      margin: 0 30px;
    }
  }

  .c-hero__overlay--left-col & {
    width: 100%;

    @include bp-large {
      margin: 20px 0;
    }

    @include bp-xlarge {
      margin: 25px 0;
    }
  }
}

.c-hero__overlay-buttons {
  font-size: 3.6rem;
  line-height: 1.2;
  font-weight: 700;
  margin-top: 20px;
  width: 100%;

  @include bp-medium {
    margin-top: 0;
  }

  &--left-col {
    font-size: 3.2rem;
    width: calc(100% + 20px);

    @include bp-medium {
      margin-top: 0;
      padding-top: 10px;
      font-size: 2rem;
    }

    @include bp-large {
      font-size: 2.6rem;
    }

    @include bp-xlarge {
      font-size: 3.1rem;
    }
  }

  &--left-left {
    @include bp-medium {
      position: absolute;
      bottom: -20px;
      left: 5%;
      width: 90%;
      transform: translateY(100%);
    }

    @include bp-xlarge {
      bottom: -40px;
      left: 10%;
      width: 80%;
    }
  }

  &--left-right {
    @include bp-medium {
      position: absolute;
      width: 420px;
      left: 0;
      bottom: 0;
      transform: translateX(80%);
    }

    @include bp-large {
      width: 500px;
      transform: translateX(100%);
    }

    @include bp-xlarge {
      width: 620px;
    }
  }
}

.c-hero__overlay-buttons-label {
  text-align: center;
  color: $color-blue;
  margin-bottom: 7px;

  @include bp-medium {
    color: $color-white;
  }
}

.c-hero__overlay-buttons-columns {
  display: flex;
  flex-wrap: wrap;
}

.c-hero__overlay-buttons-column {
  flex: 0 0 auto;
  width: 100%;
  padding: 0 5px;
  margin-bottom: 5px;

  @include bp-small {
    width: 50%;
  }

  @include bp-medium {
    padding: 0 10px;
  }
}

.c-hero__overlay-buttons-link {
  display: block;
  background: $color-red;
  color: $color-white;
  border-radius: 4px;
  text-align: center;
  text-decoration: none;
  padding: 2px 5px;

  overflow: hidden;
  text-overflow: ellipsis;
}
