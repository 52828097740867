/* ==========================================================================
   #HEADER
   ========================================================================== */

.c-header {
  position: relative;
  z-index: 100;
  padding: 15px 0;
  background-color: $color-white;
  transition: box-shadow 0.25s;

  @include bp-large {
    padding: 30px 0 40px;
  }

  &.is-mobile-menu-open {
    box-shadow: 0 0 10px 2px rgba($color-black, 0.3);

    @include bp-large {
      box-shadow: none;
    }
  }
}

.c-header__content {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  @include bp-large {
    justify-content: center;
  }

  @include bp-xlarge {
    justify-content: space-between;
  }
}

.c-header__left {
  flex: 0 0 auto;

  @include bp-large {
    width: auto;
  }

  @include bp-xlarge {
    width: auto;
  }
}

.c-header__left-link {
  display: block;
  text-decoration: none;
  margin-right: 35px;
}

.c-header__left-logo {
  width: 180px;
  height: auto;

  @include bp-medium {
    width: 260px;
  }
}

.c-header__right {
  flex: 0 0 auto;
  padding: 5px 0;
}

.c-header__right-nav {
  display: none;

  @include bp-large {
    display: block;
  }
}

.c-header__right-mobile-open {
  @include btn-reset;

  @include bp-large {
    display: none;
  }
}
