.c-main-nav-mobile {
  position: absolute;
  bottom: 1px;
  left: 0;
  width: 100%;
  height: auto;
  transform: translate3d(0, 100%, 0);

  z-index: 15;

  @include bp-small {
    width: 400px;
    left: 50%;
    transform: translate3d(-50%, 100%, 0);
  }

  @include bp-large {
    display: none;
  }

  transition: visibility 0s 0.25s, opacity 0.25s;
  visibility: hidden;
  opacity: 0;

  &.is-mobile-menu-open {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 100%;
    height: calc(100% - 10px);

    box-shadow: 0 0 10px 2px rgba($color-black, 0.3);
  }
}

.c-main-nav-mobile__inner {
  padding: 10px 0;
  background-color: $color-white;
  position: relative;
}

.c-main-nav-mobile__list {
  list-style: none;
  margin: 0;
}

.c-main-nav-mobile__item {
  margin: 0;
  font-size: 2.5rem;
  font-weight: 700;
  color: $color-red;
  padding: 5px 0;

  &--blue {
    color: $color-blue;
  }
}

.c-main-nav-mobile__item-link {
  @extend .c-main-nav__item-link;
}

.c-main-nav-mobile__item-button {
  @include btn-reset;
  font: inherit;
  line-height: inherit;
  color: inherit;
  display: block;
  cursor: pointer;

  width: 100%;
  text-align: left;

  padding-right: 20px;
  position: relative;

  &:after {
    content: '+';
    position: absolute;
    top: 0;
    right: 0;

    .is-open > & {
      content: '\2212';
    }
  }
}

.c-main-nav-mobile__sublist {
  list-style: none;
  margin: 0;
}

.c-main-nav-mobile__subitem {
  margin: 0;

  font-size: 2.3rem;
  font-weight: 400;
  padding-bottom: 5px;

  &:last-of-type {
    padding-bottom: 0;
  }
}

.c-main-nav-mobile__subitem-link {
  display: block;
  text-decoration: none;
}
