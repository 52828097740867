// @TODO: icon positioning

.c-sections__heading {
  color: $color-red;
  margin-bottom: 0.5em;
}

.c-sections__section {
  margin-bottom: 40px;
}

.c-sections__section-icon-wrapper {
  background-color: $color-blue-light;
  padding: 10px 0;
  margin-bottom: 20px;
}

.c-sections__section-icon {
  display: block;
  fill: $color-blue;
  height: 80px;
  width: 200px;
}

.c-sections__section-heading {
  color: $color-red;
  margin-bottom: 0.5em;
}
