.c-home-buttons {
  overflow: hidden;
  margin-bottom: 65px;
}

.c-home-buttons__heading {
  text-align: center;
  color: $color-red;
  margin-bottom: 25px;
}

.c-home-buttons__buttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: -10px -20px;

  @include bp-medium {
    margin: -10px -35px;
  }
}

.c-home-buttons__button {
  flex: 0 0 auto;
  padding: 10px 20px;

  min-width: 250px + 20 * 2;

  @include bp-medium {
    min-width: 295px + 35 * 2;
    padding: 10px 35px;
  }
}

.c-home-buttons__button-link {
  display: block;
  background: $color-red;
  color: $color-white;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  padding: 10px 5px;
}
