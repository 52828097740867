/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1,
.h1 {
  font-size: 4rem;

  @include bp-medium {
    font-size: 4.5rem;
  }
}

h2,
.h2 {
  font-size: 3rem;

  @include bp-medium {
    font-size: 3.8rem;
  }
}

h3,
.h3 {
  font-size: 2.8rem;
}

h4,
.h4 {
  font-size: 2.5rem;
}

h5,
.h5 {
  font-size: 2.3rem;
}

h6,
.h6 {
  font-size: 2rem;
}
