.c-home-icons {
  margin-bottom: 40px;
}

.c-home-icons__heading {
  color: $color-red;
  text-align: center;
  margin-bottom: 25px;
}

.c-home-icons__grid {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -30px;
}

.c-home-icons__col {
  flex: 0 0 auto;
  width: 50%;
  margin-bottom: 30px;
  padding: 0 5px;

  @include bp-medium {
    width: 33.333332%;
  }

  @include bp-large {
    width: 25%;
  }
}

.c-home-icons__icon-wrapper {
  margin-bottom: 15px;
}

.c-home-icons__icon {
  display: block;
  width: 100%;
  height: 50px;
  fill: $color-blue;

  @include bp-small {
    height: 80px;
  }
}

.c-home-icons__label {
  color: $color-blue;
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;

  @include bp-small {
    font-size: 2.5rem;
  }
}
