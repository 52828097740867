.c-main-nav-mobile-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba($color-black, 0.3);
  z-index: 10;

  transition: visibility 0s 0.25s, opacity 0.25s;
  visibility: hidden;
  opacity: 0;

  &.is-mobile-menu-open {
    opacity: 1;
    visibility: visible;
    transition-delay: 0s;
  }

  @include bp-large {
    display: none;
  }
}
