@mixin btn-reset {
  // based on https://gist.github.com/anthonyshort/552543
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
}
