.c-wysiwyg-columns {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1.2em;
}

.c-wysiwyg-columns__column {
  width: 100%;

  > :last-child {
    margin-bottom: 0;
  }

  @include bp-medium {
    width: auto;
    margin-right: 80px;
  }
}
