.c-storage-offers__cols-big {
  display: flex;
  flex-wrap: wrap;
}

.c-storage-offers__cols-big-col {
  flex: 0 0 auto;
  width: 100%;

  &:nth-child(1) {
    display: flex;
    flex-direction: row-reverse;

    @include bp-medium {
      display: block;
      width: 30%;
    }

    .c-storage-offers__top & {
      display: none;

      @include bp-medium {
        display: block;
      }
    }
  }

  &:nth-child(2) {
    @include bp-medium {
      width: 70%;
    }
  }
}

.c-storage-offers__cols {
  display: flex;
  margin: 0 -5px;
}

.c-storage-offers__cols-col {
  flex: 1 0 auto;
  padding: 0 5px;

  &:nth-child(1) {
    width: 25%;

    @include bp-large {
      width: 22%;
    }
  }

  &:nth-child(2) {
    width: 27%;

    @include bp-large {
      width: 30%;
    }
  }

  &:nth-child(3) {
    width: 28%;
  }

  &:nth-child(4) {
    flex: 0 0 auto;
    width: 37px;

    @include bp-medium {
      width: 20%;
    }
  }

  .c-storage-offers__bottom &:not(:nth-child(1)) {
    padding-top: 9px;

    @include bp-medium {
      padding-top: 15px;
    }
  }
}

.c-storage-offers__top {
  color: $color-red;
  font-size: 1.8rem;
  font-weight: 700;
  margin-bottom: 10px;

  @include bp-medium {
    margin-bottom: 25px;
    font-size: 2.2rem;
  }
}

.c-storage-offers__bottom {
  background-color: $color-blue-light;
  font-size: 1.5rem;
  font-weight: 700;
  color: $color-blue;
  padding-top: 15px;

  @include bp-medium {
    font-size: 1.8rem;
  }
}

.c-storage-offers__category {
  &:not(:first-child) {
    padding-top: 20px;

    @include bp-medium {
      padding-top: 40px;
    }
  }

  &:not(:last-child) {
    border-bottom: 2px solid $color-blue;
  }
}

.c-storage-offers__category-info {
  margin-bottom: 20px;
  flex: 1 1 auto;

  @include bp-medium {
    margin-bottom: 0;
    padding-right: 20px;
    max-width: 260px;
  }
}

.c-storage-offers__category-title {
  font-size: 2.4rem;
}

.c-storage-offers__category-text {
  font-weight: 400;

  @include bp-medium {
    margin-bottom: 15px;
  }
}

.c-storage-offers__category-icon {
  flex: 0 0 auto;
  width: 25%;
  max-width: 150px;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 20px;

  @include bp-medium {
    margin-right: 0;
    width: auto;
  }
}

.c-storage-offers__category-icon-icon {
  display: block;
  width: 100%;
  height: 50px;
  fill: $color-blue;
  margin-bottom: 5px;

  @include bp-medium {
    height: 130px;
    margin-bottom: 20px;
  }
}

.c-storage-offers__category-icon-label {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1;

  @include bp-medium {
    font-size: 2.4rem;
  }
}

.c-storage-offers__category-products {
  @include bp-medium {
    padding-top: 20px;
  }
}

.c-storage-offers__product {
  margin-bottom: 20px;

  @include bp-medium {
    margin-bottom: 25px;
  }
}

.c-storage-offers__product-price {
  span {
    color: $color-red;
  }
}

.c-storage-offers__product-size-big {
  font-size: 2.5rem;

  @include bp-medium {
    font-size: 3.6rem;
    margin-bottom: 3px;
  }
}

.c-storage-offers__product-size-small {
  font-weight: 400;
}

.c-storage-offers__product-link-wrapper {
  text-align: right;

  @include bp-medium {
    padding-right: 20px;
  }
}

.c-storage-offers__product-link {
  display: inline-block;
  text-decoration: none;
  background-color: $color-blue;
  color: $color-white;
  font-size: 2.2rem;
  padding: 6px;
  border-radius: 3px;

  @include bp-medium {
    padding: 5px 10px;
  }
}

.c-storage-offers__product-link-text {
  display: none;

  @include bp-medium {
    display: block;
  }
}

.c-storage-offers__product-link-icon {
  display: block;
  width: 15px;
  height: 15px;
  fill: $color-white;

  @include bp-medium {
    display: none;
  }
}
