.c-storage-offers-quick-guide {
  margin-bottom: 25px;

  @include bp-medium {
    margin-bottom: 50px;
  }
}

.c-storage-offers-quick-guide__heading {
  color: $color-red;
  margin-bottom: 20px;

  @include bp-medium {
    margin-bottom: 40px;
  }
}

.c-storage-offers-quick-guide__row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: -20px;
}

.c-storage-offers-quick-guide__col {
  flex: 0 0 auto;
  width: 50%;
  padding: 0 10px;
  margin-bottom: 20px;

  @include bp-medium {
    width: 33.333332%;
  }

  @include bp-large {
    width: 20%;
  }
}

.c-storage-offers-quick-guide__link {
  display: block;
  text-decoration: none;

  color: $color-blue;
  fill: $color-blue;
  text-align: center;
}

.c-storage-offers-quick-guide__icon {
  display: block;
  width: 100%;
  height: 80px;
  margin-bottom: 10px;

  @include bp-medium {
    margin-bottom: 20px;
    height: 135px;
  }
}

.c-storage-offers-quick-guide__label {
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.1;

  @include bp-medium {
    font-size: 2.4rem;
  }
}
